<template>
<div>
<!--工具栏-->
<div id="toolbar" ref="toolbar">
<div id="toolsbarLeft" ref="toolsbarLeft">
    <!--标题-->
    <Icon type="logo-codepen" size="28" v-show="titleShow"/>
    <div style="margin-top:3px;padding:0 5px;font-size:16px;white-space:nowrap;" v-show="titleShow" ref="title"><slot name="title"></slot></div>

<slot name="toolsbarLeft">
<!--靠左工具-->
</slot>
</div>
<div id="toolsbarRight" ref="toolsbarRight">
<slot name="toolsbarRight">
<!--靠右工具-->
</slot>

</div>

  <!--  靠左工具另起一行-->
<!--  <div id="toolsbarLeftBottom" ref="toolsbarLeftBottom">-->
<!--    <slot name="toolsbarLeftBottom">-->
<!--      &lt;!&ndash;靠左工具&ndash;&gt;-->
<!--    </slot>-->
<!--  </div>-->

</div>


<!--编辑区-->
<div id="contentarea" ref="contentarea">
<slot name="contentArea">
</slot>
</div>
</div>
</template>
<script>
export default {
    props: {

    },
    data() {
        return{
            titleShow:false,
        }
    },
    mounted(){
        if(this.$refs.title.innerText!=""){
            this.titleShow=true;
        }
    }
}
</script>

<style scoped>
#toolbar{
    min-height:50px;background:#fafafa;display:flex;top:0;min-width:1200px;width:100%;box-shadow:0 0 1px 1px #ddd;z-index:2;
}
#toolsbarLeft{
    margin:10px;float:left;display:flex;justify-content:flex-start;width:100%;align-items: center;
}



#toolsbarLeft >div >.bread {
  margin-left: 50px;
}

#toolsbarRight{
    margin:10px;float:right;display:flex;justify-content:flex-end;width:100%;
}
#toolsbarRight button,#toolsbarLeft button{margin:0 5px;}

/*#toolsbarLeftBottom {*/
/*  background: red;*/
/*  min-width: 1200px;*/
/*  display: block;*/
/*}*/


#contentarea{
    top:calc(55px);left:0;right:0;bottom:0;line-height: 1.5em;overflow: auto;z-index: 1;box-sizing: border-box;
}
#contentarea >.container{
  padding:10px 0px 10px 15px;
}
.tooldiv {
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
</style>
